

.blog-grid-card {
    .blog-img {
        height: 230px;
        width: 100%;
        transition: all 0.8s ease;
    }
    &:hover {
        .blog-img {
            transform: scale(1.1);
        }
    }
}
