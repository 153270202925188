//
// _topbar.scss
//

#page-topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background-color: var(--#{$prefix}header-bg);
    transition: all 0.1s ease-out;
    border-bottom: 1px solid var(--#{$prefix}header-border);

    &.topbar-shadow {
        box-shadow: $box-shadow;
    }

    @media (min-width: 768px) {
        left: var(--#{$prefix}vertical-menu-width);
    }
}

.navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: $header-height;
    padding: 0 $grid-gutter-width 0 calc(#{$grid-gutter-width} * 0.5)
    /*rtl: 0 0 0 calc(#{$grid-gutter-width} * 0.5) */
    ;

    @media (max-width: 767.98px) {
        padding: 0 calc(#{$grid-gutter-width} * 0.5) 0 calc(#{$grid-gutter-width} * 0.5);
    }

    .topbar-head-dropdown {
        .dropdown-menu.show {
            top: 13px !important;
        }

        .notification-actions {
            display: none;
            position: absolute;
            background-color: $dropdown-bg;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 7px;
            border-top: 1px solid var(--#{$prefix}border-color);
        }
    }

    .btn-topbar {
        height: 42px;
        width: 42px;
        color: var(--#{$prefix}header-item-sub-color);
        transition: all 0.5s ease;

        &:hover, &:focus, &:active, &.active {
            background-color: var(--#{$prefix}header-item-bg);
        }

        @media (max-width: 360px) {
            height: 36px;
            width: 36px;
        }
    }


    .user-name-sub-text {
        color: var(--#{$prefix}header-item-sub-color);
    }

    .user-name-text {
        color: var(--#{$prefix}header-item-color);
    }
}

/* Search */

.app-search {
    padding: calc(#{$header-height - 38px} * 0.5) 0;

    .form-control {
        border: none;
        height: 38px;
        padding-left: 40px;
        padding-right: 30px;
        background-color: var(--#{$prefix}topbar-search-bg);
        color: var(--#{$prefix}topbar-search-color);
        box-shadow: none;
    }

    span.search-widget-icon {
        position: absolute;
        z-index: 10;
        font-size: 18px;
        line-height: 38px;
        left: 13px;
        top: 0;
        color: var(--#{$prefix}header-item-sub-color);
    }

    .search-widget-icon-close {
        right: 7px;
        left: auto !important;
    }

    @media (max-width: 1023.99px) {
        padding-left: calc(#{$grid-gutter-width} * 0.5);
    }

    ::-webkit-input-placeholder {
        /* Edge */
        color: var(--#{$prefix}header-item-sub-color);
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--#{$prefix}header-item-sub-color);
    }

    ::placeholder {
        color: var(--#{$prefix}header-item-sub-color);
    }
}

// Mega menu

.megamenu-list {
    li {
        position: relative;
        padding: 5px 0px;

        a {
            color: $dropdown-color;
        }
    }
}

@media (max-width: 767.98px) {
    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: inline-block;
        }
    }
}

.header-item {
    height: $header-height;
    display: flex;
    align-items: center;
}

.header-profile-user {
    height: 32px;
    width: 32px;
}

.topbar-badge-sm {
    right: 0;
    top: 7px !important;
}

.topbar-badge {
    right: -9px;
    top: 4px !important;
}

.topbar-user {
    .dropdown-menu {
        top: 6px !important;
    }
}

.notification-item {
    padding: 0.75rem 1rem;
    white-space: inherit;
    position: relative;

    .form-check-input {
        position: relative;
        z-index: 2;
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    border: 1px solid transparent;
    color: var(--#{$prefix}dropdown-link-color);

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: var(--#{$prefix}dropdown-link-hover-bg);
    }
}

// Full Screen
.fullscreen-enable {
    [data-toggle="fullscreen"] {
        .bx-fullscreen::before {
            content: "\eacb";
        }
    }
}

// Dark mode enable
[data-bs-theme="dark"] {
    .light-dark-mode {
        .bx-moon::before {
            content: "\ec34";
        }
    }
}

[data-topbar="dark"] {
    .logo-dark {
        display: none;
    }

    .logo-light {
        display: inline-block;
    }
}

[data-bs-theme="dark"] {
    &[data-topbar="light"] {
        .logo-dark {
            display: none;
        }

        .logo-light {
            display: inline-block;
        }
    }
}

@media (max-width: 600px) {
    .navbar-header {
        .dropdown {
            position: static;

            .dropdown-menu {
                width: 100%;
            }
        }
    }
}

@media (max-width: 767.98px) {
    #search-dropdown-reponsive {
        top: 54px !important;
    }
}

[data-layout="vertical"] {
    &[data-layout-style="detached"] {
        @media (min-width: 1024.1px) {
            #page-topbar {
                left: 0 !important;
                box-shadow: $box-shadow;
            }

            .horizontal-logo {
                display: inline-block;
                padding-left: 0;
            }

            .topnav-hamburger {
                visibility: hidden;
            }

            .layout-width {
                max-width: 95%;
                margin: 0 auto;
            }

            &:is([data-sidebar-size="sm"], [data-sidebar-size="sm-hover"]) {
                .navbar-brand-box {
                    background-color: transparent !important;
                    position: relative;
                    width: auto;
                    text-align: left;

                    .logo-sm {
                        display: none;
                    }

                    .logo-lg {
                        display: block;
                    }
                }
            }
        }

        &[data-topbar="dark"] {
            .horizontal-logo {
                .logo-dark {
                    display: none;
                }

                .logo-light {
                    display: block;
                }
            }
        }
    }
}

[data-layout="horizontal"] {
    #page-topbar {
        left: 0;
        border-bottom: 1px solid var(--#{$prefix}header-border);

        @media (min-width: 1024.1px) {
            &.topbar-shadow {
                box-shadow: none;
            }
        }
    }

    .page-content {
        @media (min-width: 1024.1px) {
            margin-top: $header-height;
        }

        @media (max-width: 1024.1px) {
            padding: calc(45px + #{$grid-gutter-width} * 1.8) calc(#{$grid-gutter-width} * 0.5) $footer-height calc(#{$grid-gutter-width} * 0.5);
        }

        padding: calc(45px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} * 0.5) $footer-height calc(#{$grid-gutter-width} * 0.5);
    }

    &[data-layout-width="boxed"] {
        .page-content {
            @media (min-width: 1024.1px) {
                min-height: calc(100vh - #{$footer-height + $header-height});
            }
        }
    }
}

[data-layout="vertical"] {

    &:is([data-sidebar-size="sm"], [data-sidebar-size="sm-hover"]) {
        #page-topbar {
            @media (min-width: 768px) {
                left: var(--#{$prefix}vertical-menu-width-sm);
            }
        }
    }

    &[data-sidebar-size="md"] {
        #page-topbar {
            @media (min-width: 768px) {
                left: var(--#{$prefix}vertical-menu-width-md);
            }
        }
    }
}

[data-layout="twocolumn"] {
    #page-topbar {
        @media (min-width: 768px) {
            left: calc(#{$twocolumn-menu-iconview-width} + #{$twocolumn-menu-width});
        }
    }

    .horizontal-logo {
        display: none;
    }
}
