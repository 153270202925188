//
// echarts.scss
//

.e-charts{
    height: 350px;
}

.e-charts-height{
    height: 300px;
}
