//
// _list-group.scss
//


@each $color,
$value in $theme-colors {
    .list-group-fill-#{$color} {
        &.list-group-item {
            color: color-contrast($value);
            background-color: var(--#{$prefix}#{$color}) !important;
            border-color: var(--#{$prefix}#{$color});
        }
    }
}

.list-group-item {
    margin-bottom: 0px;
    .list-text {
        color: var(--#{$prefix}secondary-color);
    }
    &.active {
        box-shadow: $element-shadow;
        .list-title {
            color: $white;
        }
        .list-text {
            color: rgba($white, 0.50);
        }
    }
}
