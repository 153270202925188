// 
// toastify.scss
// 


.toastify {
    padding: 12px 16px;
    color: $white;
    display: inline-block;
    box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(77, 96, 232, 0.3);
    background: var(--#{$prefix}success);
    position: fixed;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    max-width: calc(50% - 20px);
    z-index: 2147483647;
    &.on {
        opacity: 1;
    }
}


.toast-close {
    opacity: 0.4;
    padding: 0 5px;
    position: relative;
    left: 4px;
    margin-left: 4px;
    border: none;
    background: none;
    color: $white;
}

.toastify-right {
    right: 15px;
}

.toastify-left {
    left: 15px;
    .toast-close {
        left: -4px;
        margin-left: 0;
        margin-right: 4px;
    }
}

.toastify-top {
    top: -150px;
}

.toastify-bottom {
    bottom: -150px;
}

.toastify-rounded {
    border-radius: 25px;
}

.toastify-avatar {
    width: 1.5em;
    height: 1.5em;
    margin: -7px 5px;
    border-radius: 2px;
}

.toastify-center {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
    max-width: -moz-fit-content;
}

@media only screen and (max-width: 360px) {
    .toastify-right, .toastify-left {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: fit-content;
    }
}
